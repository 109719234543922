// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$midwinter-digital-accelerators-engagement-agreement-primary: mat.define-palette(mat.$indigo-palette);
$midwinter-digital-accelerators-engagement-agreement-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$midwinter-digital-accelerators-engagement-agreement-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$midwinter-digital-accelerators-engagement-agreement-theme: mat.define-light-theme((color: (primary: $midwinter-digital-accelerators-engagement-agreement-primary,
        accent: $midwinter-digital-accelerators-engagement-agreement-accent,
        warn: $midwinter-digital-accelerators-engagement-agreement-warn,
      ),
      typography: mat.define-typography-config(),
      density: -1));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($midwinter-digital-accelerators-engagement-agreement-theme);

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  font-family: sans-serif;
  margin-left: 6.688rem;
  margin-right: 6.688rem;
  padding: 0;
  margin-top: 0;
}

ul,
ol {
  text-indent: 0;
  padding-inline-start: 1.5em;
}

p:empty::before {
  content: '';
  display: inline-block;
}

img {
  object-fit: contain;
}

svg {
  overflow: visible;
}

:host,
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  flex-shrink: 0;
  text-decoration: none;
  min-width: 0;
  overflow-wrap: anywhere;

}

.requiredStar {
  margin-left: 3px !important;
  color: #e00842;
}

html {
  --mdc-dialog-container-shape: 16px;
  --mdc-circular-progress-active-indicator-color: #E00842;
  --mdc-slider-inactive-track-color: #D6D8DA;
  --mdc-slider-active-track-color: #082065;
  --mdc-slider-active-track-height: 8px;
  --mdc-slider-inactive-track-height: 8px;
  --mdc-slider-label-container-color: #333D47;
  --mat-slider-value-indicator-opacity: 1;
  --mdc-slider-label-label-text-font: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  --mdc-slider-label-label-text-line-height: 20px;
  --mdc-slider-label-label-text-weight: 400;
  --mdc-slider-handle-height: 24px;
  --mdc-slider-handle-width: 24px;
  --mdc-slider-handle-shape: 48px;
  --mdc-slider-handle-color: #fff;
  --mdc-slider-focus-handle-color: #fff;
  --mdc-slider-hover-handle-color: #fff;
  --mat-slider-ripple-color: #082065;
  --mat-slider-hover-state-layer-color: rgba(8, 32, 101, 0.05);
  --mat-slider-focus-state-layer-color: rgba(8, 32, 101, 0.05);
  --mat-expansion-container-shape: 0px;
  --mat-expansion-header-text-font: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  --mat-expansion-header-text-size: 16px;
  --mat-expansion-header-text-weight: 600;
  --mat-expansion-header-text-line-height: 24px;
  --mat-expansion-header-indicator-color: #333D47;
  --mat-expansion-header-collapsed-state-height: 72px;
  --mat-expansion-header-expanded-state-height: 72px;
  --mdc-outlined-text-field-container-shape: 8px;
  --mdc-filled-text-field-focus-active-indicator-height: 1px;
  --mdc-outlined-text-field-focus-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-color: #858B91;
  --mat-option-selected-state-label-text-color: #14181C;
  --mat-form-field-container-text-font: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  --mdc-outlined-text-field-label-text-font: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  --mat-form-field-subscript-text-font: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  --mat-option-label-text-font: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  --mat-select-trigger-text-font: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  --mat-select-enabled-trigger-text-color: #14181C;
  --mdc-checkbox-selected-icon-color: #858B91;
  --mdc-checkbox-selected-icon-color: #858B91;
}

.section {
  background-image: url(assets/mountain-icon.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
}

.mainButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  gap: 4px;

  width: 140px;
  height: 48px;
  background: #E00842;
  border-radius: 8px;
  flex: none;
  order: 2;
  flex-grow: 0;


  font-family: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  letter-spacing: 0.1px;

  color: #FFFFFF !important;

  &:hover {
    background: #B30635;
  }

  cursor: pointer;
}

.altButton {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 16px;
  gap: 4px;

  width: 80px;
  height: 48px;

  background: #FFFFFF;
  border: 1px solid #E00842;
  border-radius: 8px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;

  font-family: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 143% */
  text-align: center;
  letter-spacing: 0.1px;

  color: #E00842 !important;

  &:hover {
    background: rgba(228, 12, 66, 0.08);
  }

  cursor: pointer;
}

.accordionSingleClient,
.accordionJointClient {
  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px 0px !important;
  }

  .mat-expansion-panel-header,
  .mat-expansion-panel-body {
    border-bottom: 1px solid #D6D8DA;
  }

  .mat-expansion-panel-spacing {
    margin-bottom: 0 !important;
  }
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  --mat-tab-header-label-text-size: 16px;
  --mat-tab-header-label-text-line-height: 24px;
  --mat-tab-header-label-text-weight: 600;
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-active-label-text-color: #14181C;
  --mat-tab-header-inactive-label-text-color: #666E75;
  --mat-tab-header-inactive-focus-label-text-color: #666E75;
  --mat-tab-header-inactive-hover-label-text-color: #666E75;
  --mat-tab-header-active-focus-label-text-color: #14181C;
  --mat-tab-header-active-hover-label-text-color: #14181C;
  --mdc-tab-indicator-active-indicator-color: #E00842;
  --mat-tab-header-active-ripple-color: rgba(228, 12, 66, 0.08);
  ;
  --mat-tab-header-inactive-ripple-color: rgba(228, 12, 66, 0.08);
  --mat-tab-header-active-hover-indicator-color: #E00842;
  --mat-tab-header-active-focus-indicator-color: #E00842;
}

.accordionContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  >.accordionRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    >.editContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 8px 0px;
      gap: 4px;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      width: 100%;

      >.editButton {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        border: none;

        font-family: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
          'Liberation Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #14181C !important;
        gap: 4px;
        /* Inside auto layout */
        flex: none;
        order: 1;
        flex-grow: 0;
        text-underline-offset: 5px;
        cursor: pointer;

        &:hover {
          background: none;
          font-weight: 650;
        }
      }
    }

    >.titleColumn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 0;

      width: 35%;

      background: #FFFFFF;

      font-family: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #14181C;
      flex: none;
      order: 0;
      flex-grow: 1;
    }

    >.contentColumn {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 8px 0;

      width: 65%;

      background: #FFFFFF;

      font-family: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #14181C;
      flex: none;
      order: 1;
      flex-grow: 1;
    }
  }
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  border-radius: 8px !important;
  margin-top: 3px !important;
}

.mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #14181C;
}

.fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  z-index: 0;

  >.fieldContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    isolation: isolate;
    width: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    >.fieldLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;

      font-family: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #14181C;
    }

    >.field {
      width: 100%;
    }
  }

  >.toggleContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    isolation: isolate;
    width: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 10px;

    >.toggleLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px;

      font-family: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #14181C;
      width: 90%;
    }
  }

  .toggleWithCommentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    isolation: isolate;
    width: 100%;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 10px;

    >.toggleContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      isolation: isolate;
      width: 100%;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      margin-bottom: 10px;

      >.toggleLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 4px;

        font-family: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
          'Liberation Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #14181C;
        width: 90%;
      }
    }

    >.fieldContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      isolation: isolate;
      width: 100%;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      >.fieldLabel {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 4px;

        font-family: Open Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
          'Liberation Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #14181C;
      }

      >.field {
        width: 100%;
      }
    }
  }
}


.width100Percent {
  width: 100%;
}

.aia-bold {
  font-weight: 600;
}

.wrap-radio-button-label label {
  flex-shrink: initial;
}

.hidePDFSection {
  position: absolute;
  left: -1000px;
  top: 0;
}

.note-card-text {
  u {
    text-decoration: underline;
  }

  del {
    text-decoration: line-through;
  }
}

.advice-fee-overview-list {
  list-style-image: url("/assets/green-check.svg");
}